import { Helmet } from "react-helmet";
import { userService } from "/app/src/services";
import { Routes, Route } from "react-router-dom";
import { Row, Col } from "antd";
import Menu from "./_menu";
import Profile from "./profile";
import { User as UserModel } from "/app/src/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNumberId } from "../../hooks/useNumberId";

export default function User() {
  const { userId } = useNumberId();
  const queryClient = useQueryClient();
  //Get API data with react-query
  const { data: user } = useQuery({
    queryKey: ["user", userId],
    queryFn: () => {
      return userService.getSingle(userId);
    },
    initialData: { user: {} },
    select: (data: { user: UserModel }) => {
      return data.user;
    },
  });

  //Update single user
  const userMutation = useMutation({
    mutationFn: (updatedUser: UserModel) => {
      return userService.updateSingle(user.id, updatedUser).then((response) => {
        if ("errors" in response) {
          return Promise.reject(response);
        }
        return Promise.resolve(response);
      });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["user", userId], data);
    },
  });

  const toggleUserStatus = () => {
    let status = 2;
    if (user.status === 2) {
      status = 1;
    }
    userMutation.mutate({ status });
  };

  return (
    <div className="user">
      <Helmet>
        <title>{user?.username}</title>
      </Helmet>
      <h1>{user?.username}</h1>
      <Row gutter={20}>
        <Col span={4}>
          <Menu user={user} toggleUserStatus={toggleUserStatus} />
        </Col>
        <Col span={20}>
          {user?.username && (
            <Routes>
              <Route
                path="/"
                element={
                  <Profile
                    profileUser={user}
                    updateUser={userMutation.mutateAsync}
                  />
                }
              />
            </Routes>
          )}
        </Col>
      </Row>
    </div>
  );
}
