import React, { useCallback } from "react";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { Formik } from "formik";
import { roleService } from "../../services";
import { useTranslation } from "react-i18next";
import { handleSubmissionErrors } from "../../helpers/forms";
import { Role } from "/app/src/models";
import { useQueryClient } from "@tanstack/react-query";
import { simpleSchemaBuilder } from "/app/src/helpers";
import Box from "/app/src/components/generic/components/box";

/**
 * Form to update the role name
 * @param param0 role to be edited
 */
export default function RoleForm({ role }: { role: Role }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const handleSubmit = useCallback(
    (values: { name: string }, actions) => {
      roleService
        .updateSingle(role.id, { name: values.name })
        .then((response) => {
          if ("errors" in response) {
            handleSubmissionErrors(response.errors, actions.setFieldError);
            actions.setSubmitting(false);
          } else {
            actions.resetForm();
            queryClient.setQueryData(["role", role.id], response);
          }
        });
    },
    [queryClient, role.id],
  );

  /**
   * Formik form to update the role name
   */
  const roleForm = ({
    dirty,
    isValid,
  }: {
    dirty: boolean;
    isValid: boolean;
  }) => (
    <Form layout="vertical">
      <Row justify="start" gutter={20}>
        <Col span={12}>
          <Form.Item name="name" label={t("translation:role_name")}>
            <Input suffix name="name" placeholder={role.name} size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SubmitButton
            type="primary"
            size="large"
            disabled={!(dirty && isValid)}
          >
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Box>
      <Formik
        component={roleForm}
        initialValues={{
          name: role.name,
        }}
        validationSchema={simpleSchemaBuilder([
          { name: "name", type: "string", required: true },
        ])}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}
