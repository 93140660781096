import { Formik, FormikProps } from "formik";
import { Connection } from "/app/src/models";
import { useCallback } from "react";
import { Form, Input, SubmitButton } from "formik-antd";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { simpleSchemaBuilder } from "/app/src/helpers";
import useSetting from "../setting";

interface FormValues {
  refreshEndpoint?: string;
  loginEndpoint?: string;
}

/**
 * Component to display the OAuth2 connection types.
 */
export default function OAuth2({ connection }: { connection: Connection }) {
  const { settingValue: refreshURL, createUpdateSetting: createUpdateRefresh } =
    useSetting({
      connectionId: connection.id,
      type: "refresh",
    });
  const { settingValue: loginURL, createUpdateSetting: createUpdateLogin } =
    useSetting({
      connectionId: connection.id,
      type: "login",
    });

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await createUpdateRefresh({
        type: "refresh",
        connectionId: connection.id,
        value: values.refreshEndpoint,
      });
      return createUpdateLogin({
        type: "login",
        connectionId: connection.id,
        value: values.loginEndpoint,
      });
    },

    [connection.id, createUpdateLogin, createUpdateRefresh],
  );
  const { t } = useTranslation();
  const loginForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <h3>{t("translation:endpoints")}</h3>
          <Row justify="start" gutter={16}>
            <Col span={10}>
              <Form.Item
                name="loginEndpoint"
                hasFeedback={false}
                label={t("translation:login_path")}
              >
                <Input suffix name="loginEndpoint" size="large" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name="refreshEndpoint"
                hasFeedback={false}
                label={t("translation:refresh_path")}
              >
                <Input suffix name="refreshEndpoint" size="large" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || !isValid}
                style={{ marginTop: "30px" }}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );
  return (
    <Formik
      component={loginForm}
      initialValues={{
        loginEndpoint: loginURL,
        refreshEndpoint: refreshURL,
      }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={simpleSchemaBuilder([
        { name: "loginEndpoint", type: "url", required: true },
        { name: "refreshEndpoint", type: "url", required: true },
      ])}
    />
  );
}
