import React from "react";

interface IconProps {
  color?: string;
  size?: number;
}

/**
 *
 * @param param0 color and size
 * @returns ArrowRight icon
 */
const ArrowRight: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.675 11.115L14.53 4.97L13.47 6.03L18.69 11.25H3V12.75H18.69L13.47 17.97L14.53 19.03L20.675 12.885C21.16 12.4 21.16 11.605 20.675 11.115Z"
      fill={color}
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns ArrowCircleRight icon
 */
const ArrowCircleRight: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.485 6.485 2 12 2C17.515 2 22 6.485 22 12C22 17.515 17.515 22 12 22C6.485 22 2 17.515 2 12ZM3.5 12C3.5 16.685 7.315 20.5 12 20.5C16.685 20.5 20.5 16.685 20.5 12C20.5 7.315 16.685 3.5 12 3.5C7.315 3.5 3.5 7.315 3.5 12ZM11.97 9.02997L13.03 7.96997L16.175 11.115C16.66 11.605 16.66 12.4 16.175 12.885L13.03 16.03L11.97 14.97L14.19 12.75H7.5V11.25H14.19L11.97 9.02997Z"
      fill={color}
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns ExclamationCircle icon
 */
const ExclamationCircle: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
      fill={color}
    />
    <path
      d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M12 8V13.5"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Delete icon
 */
const Delete: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.25 2.91675H9.33333V2.77091C9.33333 1.88716 8.61292 1.16675 7.72917 1.16675H6.27083C5.38708 1.16675 4.66667 1.88716 4.66667 2.77091V2.91675H1.75V3.79175H2.33333V11.2292C2.33333 12.113 3.05375 12.8334 3.9375 12.8334H10.0625C10.9462 12.8334 11.6667 12.113 11.6667 11.2292V3.79175H12.25V2.91675ZM5.54167 2.77091C5.54167 2.36841 5.86833 2.04175 6.27083 2.04175H7.72917C8.13167 2.04175 8.45833 2.36841 8.45833 2.77091V2.91675H5.54167V2.77091ZM10.7917 11.2292C10.7917 11.6317 10.465 11.9584 10.0625 11.9584H3.9375C3.535 11.9584 3.20833 11.6317 3.20833 11.2292V3.79175H10.7917V11.2292ZM6.125 5.54175H5.25V10.2084H6.125V5.54175ZM7.875 5.54175H8.75V10.2084H7.875V5.54175Z"
      fill={color}
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Warning icon
 */
const Warning: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.42508 19.5L11.5651 3.51001C11.7551 3.17501 12.2401 3.17501 12.4351 3.51001L21.5751 19.5C21.7651 19.835 21.5251 20.25 21.1401 20.25H2.86008C2.47508 20.25 2.23508 19.835 2.42508 19.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
      fill={color}
    />
    <path
      d="M12 9V14.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns ArrowBoxRight icon
 */
const ArrowBoxRight: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 16L13.9198 12.1881C14.0267 12.084 14.0267 11.916 13.9198 11.8119L10 8"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M18.5 4H6C4.89543 4 4 4.89543 4 6V18.5C4 19.6046 4.89543 20.5 6 20.5H18.5C19.6046 20.5 20.5 19.6046 20.5 18.5V6C20.5 4.89543 19.6046 4 18.5 4Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Locked icon
 */
const Locked: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 11.25V8C6.75 5.1 9.1 2.75 12 2.75C14.9 2.75 17.25 5.1 17.25 8V11.25"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M17.25 11.25H6.75C5.64543 11.25 4.75 12.1454 4.75 13.25V19.25C4.75 20.3546 5.64543 21.25 6.75 21.25H17.25C18.3546 21.25 19.25 20.3546 19.25 19.25V13.25C19.25 12.1454 18.3546 11.25 17.25 11.25Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Unlocked icon
 */
const Unlocked: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75 11.255V8.005C6.75 5.105 9.1 2.755 12 2.755C14.175 2.755 16.04 4.08 16.835 5.965"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M17.25 11.25H6.75C5.64543 11.25 4.75 12.1454 4.75 13.25V19.25C4.75 20.3546 5.64543 21.25 6.75 21.25H17.25C18.3546 21.25 19.25 20.3546 19.25 19.25V13.25C19.25 12.1454 18.3546 11.25 17.25 11.25Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns DragIndicator icon
 */
const DragIndicator: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6747 11.115L19.0297 8.46999L17.9697 9.52999L19.6897 11.25H12.7497V4.30999L14.4697 6.02999L15.5297 4.96999L12.8847 2.32499C12.3997 1.83999 11.6047 1.83999 11.1147 2.32499L8.46969 4.96999L9.52969 6.02999L11.2497 4.30999V11.25H4.30969L6.02969 9.52999L4.96969 8.46999L2.32469 11.115C1.83969 11.6 1.83969 12.395 2.32469 12.885L4.96969 15.53L6.02969 14.47L4.30969 12.75H11.2497V19.69L9.52969 17.97L8.46969 19.03L11.1147 21.675C11.3597 21.92 11.6797 22.04 11.9997 22.04C12.3197 22.04 12.6397 21.92 12.8847 21.675L15.5297 19.03L14.4697 17.97L12.7497 19.69V12.75H19.6897L17.9697 14.47L19.0297 15.53L21.6747 12.885C22.1597 12.4 22.1597 11.605 21.6747 11.115Z"
      fill={color}
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Checkmark icon
 */
const Checkmark: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.25 6.245L9.105 17.39C8.91 17.585 8.595 17.585 8.4 17.39L3.75 12.745"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Undo icon
 */
const Undo: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 7.75H15C17.9 7.75 20.25 10.1 20.25 13C20.25 15.9 17.9 18.25 15 18.25H7"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M7.50023 3.5L3.60523 7.395C3.41023 7.59 3.41023 7.905 3.60523 8.1L7.50023 11.995"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Plus icon
 */
const Plus: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 4V20" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" />
    <path d="M20 12H4" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Star Outlined icon
 */
const StarOutlined: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9948 14.3749L18.0048 20.2499C18.0748 20.6599 17.6448 20.9699 17.2798 20.7749L12.0048 17.9999L6.72983 20.7749C6.36483 20.9699 5.93483 20.6549 6.00483 20.2499L7.01483 14.3749L2.73483 10.2149C2.43983 9.92495 2.59983 9.41995 3.00983 9.35995L8.90983 8.50495L11.5498 3.15995C11.7348 2.78995 12.2648 2.78995 12.4448 3.15995L15.0848 8.50495L20.9848 9.35995C21.3948 9.41995 21.5598 9.92495 21.2598 10.2149L16.9948 14.3749Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Star Filled icon
 */
const StarFilled: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9948 14.3749L18.0048 20.2499C18.0748 20.6599 17.6448 20.9699 17.2798 20.7749L12.0048 17.9999L6.72983 20.7749C6.36483 20.9699 5.93483 20.6549 6.00483 20.2499L7.01483 14.3749L2.73483 10.2149C2.43983 9.92495 2.59983 9.41995 3.00983 9.35995L8.90983 8.50495L11.5498 3.15995C11.7348 2.78995 12.2648 2.78995 12.4448 3.15995L15.0848 8.50495L20.9848 9.35995C21.3948 9.41995 21.5598 9.92495 21.2598 10.2149L16.9948 14.3749Z"
      fill={color}
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Close icon
 */
const Close: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.75 4.25L4.25 19.75"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M4.25 4.25L19.75 19.75"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns InfoCircle icon
 */
const InfoCircle: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M12.75 16V10.5H11.25V16H12.75Z" fill={color} />
    <path
      d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
      fill={color}
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns QuestionCircle icon
 */
const QuestionCircle: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M9.375 9.015C9.805 7.98 10.825 7.25 12.015 7.25C13.59 7.25 14.87 8.53 14.87 10.105C14.87 12.75 12 12.25 12 14.5V15"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M12 18.5C12.5523 18.5 13 18.0523 13 17.5C13 16.9477 12.5523 16.5 12 16.5C11.4477 16.5 11 16.9477 11 17.5C11 18.0523 11.4477 18.5 12 18.5Z"
      fill={color}
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns PlusCircle icon
 */
const PlusCircle: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M12 7.5V16.5"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M16.5 12H7.5"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Edit icon
 */
const Edit: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.555 3.555L16.0247 3.02467L16.555 3.555ZM3.555 16.555L3.02467 16.0247L2.85608 16.1933L2.81579 16.4283L3.555 16.555ZM2.75 21.25L2.01079 21.1233C1.96965 21.3632 2.04753 21.6082 2.21967 21.7803C2.39181 21.9525 2.6368 22.0304 2.87674 21.9892L2.75 21.25ZM7.445 20.445L7.57174 21.1842L7.80674 21.1439L7.97533 20.9753L7.445 20.445ZM20.445 7.445L20.9753 7.97533L20.445 7.445ZM16.0247 3.02467L3.02467 16.0247L4.08533 17.0853L17.0853 4.08533L16.0247 3.02467ZM2.81579 16.4283L2.01079 21.1233L3.48921 21.3767L4.29421 16.6817L2.81579 16.4283ZM2.87674 21.9892L7.57174 21.1842L7.31826 19.7058L2.62326 20.5108L2.87674 21.9892ZM7.97533 20.9753L20.9753 7.97533L19.9147 6.91467L6.91467 19.9147L7.97533 20.9753ZM20.9753 7.97533C22.3432 6.60744 22.3432 4.39256 20.9753 3.02467L19.9147 4.08533C20.6968 4.86744 20.6968 6.13256 19.9147 6.91467L20.9753 7.97533ZM20.9753 3.02467C19.6074 1.65678 17.3926 1.65678 16.0247 3.02467L17.0853 4.08533C17.8674 3.30322 19.1326 3.30322 19.9147 4.08533L20.9753 3.02467Z"
      fill={color}
    />
    <path
      d="M13.75 6.75L17.25 10.25"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns CircleCheck icon
 */
const CircleCheck: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M16.75 8.745L10.855 14.64C10.66 14.835 10.345 14.835 10.15 14.64L7.755 12.245"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Clip icon
 */
const Clip: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7505 11.75L20.0005 10.5C21.7955 8.705 21.7955 5.795 20.0005 4C18.2055 2.205 15.2955 2.205 13.5005 4L4.00055 13.5C2.20555 15.295 2.20555 18.205 4.00055 20C5.79555 21.795 8.70555 21.795 10.5005 20L15.2505 15.25C16.3555 14.145 16.3555 12.355 15.2505 11.25C14.1455 10.145 12.3555 10.145 11.2505 11.25L6.25055 16.25"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Menu icon
 */
const Menu: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 6.25H21"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path d="M3 12H21" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" />
    <path
      d="M3 17.75H21"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns Globe icon
 */
const Globe: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M2.75 12H21.25"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M12 2.75C12 2.75 7.75 6 7.75 12"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M12 21.25C12 21.25 7.75 18 7.75 12"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M12 2.75C12 2.75 16.25 6 16.25 12"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M12 21.25C12 21.25 16.25 18 16.25 12"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M5.08496 5.84998C6.64996 6.79498 8.97496 7.74998 12 7.74998C15.025 7.74998 17.355 6.79498 18.915 5.84998"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M18.915 18.15C17.35 17.205 15.025 16.25 12 16.25C8.97496 16.25 6.64496 17.205 5.08496 18.15"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

/**
 *
 * @param param0 color and size
 * @returns User icon
 */
const User: React.FC<IconProps> = ({ color, size }) => (
  <svg
    width={size || 24}
    height={size || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 12.25C14.6234 12.25 16.75 10.1234 16.75 7.5C16.75 4.87665 14.6234 2.75 12 2.75C9.37665 2.75 7.25 4.87665 7.25 7.5C7.25 10.1234 9.37665 12.25 12 12.25Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M3.69141 22L4.70641 18.215C5.17641 16.465 6.76141 15.25 8.57141 15.25H15.4314C17.2414 15.25 18.8264 16.465 19.2964 18.215L20.3114 22"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export {
  ArrowRight,
  ArrowCircleRight,
  ExclamationCircle,
  Delete,
  Warning,
  ArrowBoxRight,
  Locked,
  Unlocked,
  DragIndicator,
  Checkmark,
  Undo,
  Plus,
  StarOutlined,
  StarFilled,
  Close,
  InfoCircle,
  QuestionCircle,
  PlusCircle,
  Edit,
  CircleCheck,
  Clip,
  Menu,
  Globe,
  User,
};
