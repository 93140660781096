import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { transactionService } from "/app/src/services";
import { Transaction as TransactionType } from "/app/src/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Helmet } from "react-helmet";
import { Col, Row } from "antd";
import { handlePromiseError } from "/app/src/helpers/api";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useCallback } from "react";
import { Form, Select, SubmitButton } from "formik-antd";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { ExportStateTypes } from "/app/src/constants/types";
import Box from "/app/src/components/generic/components/box";

const { Option } = Select;

/**
 *
 * @param values TransactionFormValues
 * @returns Formatted Transaction
 */
function formatForm(values: TransactionFormValues): TransactionType {
  return {
    exportStateType: values.exportStateType,
  };
}

interface TransactionFormValues {
  exportStateType: number | undefined;
}

/**
 *
 * @returns Single Transaction
 */
export default function Transaction() {
  const { t } = useTranslation();
  const { transactionId } = useParams();
  const queryClient = useQueryClient();

  const { data: transaction } = useQuery({
    queryKey: ["transaction", transactionId],
    queryFn: () => {
      return transactionService.getSingle(transactionId);
    },
    initialData: { transaction: {} },
    select: (data: { transaction: TransactionType }) => {
      return data.transaction;
    },
  });

  //useQuery that updates the transaction
  const { mutateAsync: updateTransaction } = useMutation({
    mutationFn: (updatedTransaction: TransactionType) => {
      return transactionService
        .updateSingle(updatedTransaction.id, updatedTransaction)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["transaction", transactionId], response);
    },
  });

  const updateTransactionHandler = useCallback(
    async (
      values: TransactionFormValues,
      actions: FormikHelpers<TransactionFormValues>,
    ) => {
      if (transaction?.id) {
        await updateTransaction({
          id: transaction.id,
          ...formatForm(values),
        }).then(() => {
          actions.resetForm();
        });
      }
    },
    [transaction, updateTransaction],
  );

  const updateExportStateTypeForm: (
    props: FormikProps<TransactionFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={8}>
            <Form.Item
              name="exportStateType"
              label={t("translation:export_state_type")}
            >
              <Select
                name="exportStateType"
                placeholder={t("translation:export_state_type")}
                allowClear
                size="large"
              >
                {ExportStateTypes.map((key) => (
                  <Option key={key.value} value={key.value}>
                    {key.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t],
  );

  return (
    <div className="explorer">
      <Helmet>
        <title>{transaction?.id}</title>
      </Helmet>
      <Box>
        <Row>
          <Col span={16} className="bigItem">
            <h4>{t("translation:id")}</h4>
            <span>{transaction?.id}</span>
          </Col>
        </Row>
        <Row>
          <Col span={4} className="bigItem">
            <h4>{t("translation:number")}</h4>
            <p>{transaction?.number}</p>
          </Col>
          <Col span={4} className="bigItem">
            <h4>{t("translation:order_name")}</h4>
            <p>{transaction?.orderName}</p>
          </Col>
          <Col span={4} className="bigItem">
            <h4>{t("translation:type")}</h4>
            <p>{transaction?.type}</p>
          </Col>
        </Row>
        <Row>
          <Col span={4} className="bigItem">
            <h4>{t("translation:motive_type")}</h4>
            <p>{transaction?.motiveType}</p>
          </Col>
          <Col span={4} className="bigItem">
            <h4>{t("translation:creationDate")}</h4>
            <p>{transaction?.creationDate}</p>
          </Col>
          <Col span={4} className="bigItem">
            <h4>{t("translation:direction_type")}</h4>
            <p>{transaction?.directionType}</p>
          </Col>
          <Col span={4} className="bigItem">
            <h4>{t("translation:export_state_type")}</h4>
            <p>{transaction?.exportStateType}</p>
          </Col>
        </Row>
      </Box>
      <Box>
        <Formik
          component={updateExportStateTypeForm}
          initialValues={{
            exportStateType: transaction?.exportStateType,
          }}
          enableReinitialize
          validationSchema={simpleSchemaBuilder([
            { name: "exportStateType", type: "number", required: true },
          ])}
          onSubmit={updateTransactionHandler}
        />
      </Box>
    </div>
  );
}
