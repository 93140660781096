import { useCallback } from "react";
import { Integration } from "/app/src/models";
import { Formik, FormikProps } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { getTimeZones } from "/app/src/helpers/time";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { mapTimeZones } from "/app/src/components/generic/formatting/timeZone";
import useSetting from "/app/src/components/settings/setting";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { integrationService } from "/app/src/services";

interface FormattingFormValues {
  integrationName: string;
  timeZoneFormat: string;
}

/**
 *
 * @param param0 integration
 * @returns Formatting Setting component for the integration
 */
export default function Formatting({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { settingValue: timeZone, createUpdateSetting: setTimeZone } =
    useSetting({
      integrationId: integration.id,
      type: "timeZone",
    });
  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (values: Integration) => {
      return integrationService
        .updateSingle(values.id, values)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["integration", Number(integration.id)],
        response,
      );
    },
  });
  const updateFormattingForm: (
    props: FormikProps<FormattingFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Row className="pb-3">
          <Col span={6}>
            <Form.Item name="integrationName" hasFeedback={false}>
              <Input name="integrationName" size="large" />
            </Form.Item>
          </Col>
          <Col span={10} offset={8}>
            <Row justify="end">
              <SubmitButton
                disabled={!dirty || isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                {t("translation:save")}
              </SubmitButton>
            </Row>
          </Col>
        </Row>
        <Row justify="start" gutter={16}>
          <Col span={8}>
            <h3 className="font-semibold">{t("translation:formatting")}</h3>
            <p>{t("translation:formatting_description")}</p>
          </Col>
          <Col span={16}>
            <Col>
              <Form.Item name="timeZoneFormat" label="Time Zone">
                <SearchFilter
                  list={getTimeZones()}
                  name="timeZoneFormat"
                  placeholder={t("translation:select_time_zone")}
                  sort
                  mapOptionsFn={mapTimeZones}
                />
              </Form.Item>
            </Col>
          </Col>
        </Row>
      </Form>
    ),
    [t],
  );

  const formattingOnSubmit = useCallback(
    async (values: FormattingFormValues) => {
      setTimeZone({
        type: "timeZone",
        value: values.timeZoneFormat,
        integrationId: integration.id,
      });
      return await updateIntegration({
        id: integration.id,
        name: values.integrationName,
      });
    },
    [integration.id, setTimeZone, updateIntegration],
  );

  return (
    <Formik
      component={updateFormattingForm}
      initialValues={{
        integrationName: integration?.name,
        timeZoneFormat: timeZone,
      }}
      enableReinitialize
      onSubmit={formattingOnSubmit}
    />
  );
}
