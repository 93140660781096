import React, { useState, useEffect, useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { buildParams, simpleSchemaBuilder } from "/app/src/helpers";
import { reportService, reportTypeService } from "/app/src/services";
import { useQueryClient } from "@tanstack/react-query";
import { SearchFilter } from "../generic/components/searchFilter";
import Box from "/app/src/components/generic/components/box";

interface FormValues {
  name: string;
  baseTable: string;
  reportTypeId: number;
}
/**
 * New Report Form component
 * @param param0 report themes list
 * @returns
 */
export default function NewReport({
  themes,
}: {
  themes: { name: string; value: string }[];
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [reportTypeId, setReportTypeId] = useState<number>(null);

  useEffect(() => {
    reportTypeService
      .getAll(buildParams({ name: "Table" }))
      .then((response) => {
        setReportTypeId(response.report_types[0].id);
      });
  }, []);

  const newReportForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting }) => (
        <Form layout="vertical">
          <Row justify="start" gutter={16}>
            <Col span={7}>
              <Form.Item name="baseTable" label={t("translation:theme")}>
                <SearchFilter
                  list={themes}
                  placeholder={t("translation:theme")}
                  name="baseTable"
                  sort
                  allowClear={false}
                  strictFilter
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="name"
                label={t("translation:report_name")}
                hasFeedback={false}
              >
                <Input
                  name="name"
                  placeholder={t("translation:enter_name")}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={1} />

            <Col span={4}>
              <SubmitButton
                style={{ marginTop: "30px" }}
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
              >
                {t("translation:create")} {t("translation:report")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t, themes],
    );

  return (
    <Box>
      <div className="newReport">
        <Formik
          component={newReportForm}
          initialValues={{
            baseTable: "History",
            name: "",
            reportTypeId,
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
          ])}
          onSubmit={(values, actions) => {
            reportService
              .createSingle(formatForm(values, reportTypeId))
              .then((response) => {
                actions.setSubmitting(false);
                queryClient.invalidateQueries({ queryKey: ["reports"] });
                queryClient.invalidateQueries({ queryKey: ["reportsCount"] });
                navigate(`/reports/${response.report.id}`);
              });
          }}
        />
      </div>
    </Box>
  );
}

function formatForm(values: FormValues, reportTypeId: number) {
  return {
    baseTable: values.baseTable,
    name: values.name,
    reportTypeId,
  };
}
