import { Routes, Route } from "react-router-dom";
import Integration from "./integration";
import NewIntegration from "./newIntegrationForm";
import IntegrationsList from "/app/src/components/generic/components/integration/integrationsView";
import Controls from "/app/src/components/generic/components/integration/_controls";
import { useToggle } from "/app/src/hooks";
import { App } from "/app/src/models";
export default function VarianceApp({ app }: { app: App }) {
  const [toggled, setToggled] = useToggle();

  const varianceThemes = [
    { name: "Location", value: "Location", id: "Location" },
    {
      name: "Location Content Breakdown",
      value: "LocationContentBreakdown",
      id: "LocationContentBreakdown",
    },
    { name: "Material", value: "Material", id: "Material" },
  ];

  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <Controls
            app={app}
            name="variance_app"
            toggled={toggled}
            setToggled={setToggled}
          >
            <NewIntegration app={app} setToggled={setToggled} />
          </Controls>
        }
      >
        <Route
          path="integrations/:integrationId/*"
          element={<Integration app={app} />}
        />
        <Route
          path="/"
          element={
            <IntegrationsList
              app={app}
              themes={varianceThemes}
              includeType={false}
            />
          }
        />
      </Route>
    </Routes>
  );
}
