import { useCallback } from "react";
import { Integration } from "/app/src/models";
import { Formik, FormikProps } from "formik";
import { Form, InputNumber, Select, SubmitButton } from "formik-antd";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import NextButton from "/app/src/components/NextUi/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { integrationService } from "/app/src/services";
import { handlePromiseError } from "/app/src/helpers/api";

interface DataCacheFormValues {
  cache: string;
  cacheFrequencyValue: number;
  cacheFrequency: string;
}

/**
 *
 * @param param0 integration
 * @returns Data Cache Setting component for the integration
 */
export default function DataCache({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (values: Integration) => {
      return integrationService
        .updateSingle(values.id, values)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["integration", Number(integration.id)],
        response,
      );
    },
  });

  const calcInitialValues = useCallback(() => {
    if (integration?.number === 0 || integration?.number === undefined) {
      return {
        cache: "disabled",
        cacheFrequencyValue: 30,
        cacheFrequency: "s",
      };
    }
    let value = String(integration.number);
    if (value.startsWith("!")) {
      value = value.slice(1);
    }
    return {
      cache: String(integration.number).startsWith("!")
        ? "disabled"
        : "enabled",
      cacheFrequencyValue: Number(value.slice(0, -1)),
      cacheFrequency: value.slice(-1),
    };
  }, [integration]);
  const clearCache = useCallback(async () => {
    return await integrationService.clearIntegrationCache(integration.id);
  }, [integration.id]);
  const dataCacheOnSubmit = useCallback(
    async (values: DataCacheFormValues) => {
      //calculate the cache frequency in minutes
      if (values.cache === "disabled") {
        return await updateIntegration({
          id: integration.id,
          number: `!${values.cacheFrequencyValue}${values.cacheFrequency}`,
        });
      }
      return await updateIntegration({
        id: integration.id,
        number: `${values.cacheFrequencyValue}${values.cacheFrequency}`,
      });
    },
    [integration.id, updateIntegration],
  );
  const updateFormattingForm: (
    props: FormikProps<DataCacheFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting, values }) => (
      <Form layout="vertical">
        <Row justify="end" className="pb-3">
          <SubmitButton
            disabled={!dirty || isSubmitting}
            loading={isSubmitting}
            type="primary"
          >
            {t("translation:save")}
          </SubmitButton>
        </Row>
        <Row justify="start" gutter={16}>
          <Col span={8}>
            <h3 className="font-semibold">{t("translation:data_cache")}</h3>
            <p>{t("translation:data_cache_description")}</p>
          </Col>
          <Col span={16}>
            <Col>
              <Form.Item name="cache" label="Cache">
                <Select size="large" name="cache">
                  <Select.Option value="enabled">
                    {t("translation:enabled")}
                  </Select.Option>
                  <Select.Option value="disabled">
                    {t("translation:disabled")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Row className="flex items-center" justify="start" gutter={4}>
                <Col span={4}>
                  <Form.Item name="cacheFrequencyValue" label="Cache Frequency">
                    <InputNumber
                      size="large"
                      name="cacheFrequencyValue"
                      min={0}
                      disabled={values.cache === "disabled"}
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item name="cacheFrequency" label=" ">
                    <Select
                      size="large"
                      name="cacheFrequency"
                      disabled={values.cache === "disabled"}
                    >
                      <Select.Option value="s">
                        {t("translation:seconds")}
                      </Select.Option>
                      <Select.Option value="m">
                        {t("translation:minutes")}
                      </Select.Option>
                      <Select.Option value="h">
                        {t("translation:hours")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Row justify="end">
                    <NextButton
                      size="md"
                      fullWidth
                      className="mt-[6px]"
                      variant="bordered"
                      onClick={clearCache}
                    >
                      {t("translation:clear_cache")}
                    </NextButton>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <p>{t("translation:cache_enable_description")}</p>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
      </Form>
    ),
    [clearCache, t],
  );

  return (
    <Formik
      component={updateFormattingForm}
      initialValues={calcInitialValues()}
      enableReinitialize
      onSubmit={dataCacheOnSubmit}
    />
  );
}
