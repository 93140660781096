import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Row, Col } from "antd";
import { stationService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Station as StationType } from "/app/src/models";
import Box from "/app/src/components/generic/components/box";

export default function SingleStation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { stationId } = useParams();

  const goToWarehouse = () => {
    navigate(`/explorer/warehouses/${station.warehouseId}`);
  };

  //Get the Single Station from URL
  const { data: station } = useQuery({
    queryKey: ["station", stationId],
    queryFn: () => {
      return stationService.getSingle(stationId);
    },
    initialData: { station: {} },
    select: (data: { station: StationType }) => {
      return data.station;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{station?.name}</title>
      </Helmet>
      <Box>
        <Row>
          <Col span={6} className="bigItem">
            <h4>
              {t("translation:station")} {t("translation:name")}
            </h4>
            <span>{station?.name}</span>
          </Col>
        </Row>
        <Row>
          <Col span={6} className="bigItem">
            <h4>{t("translation:description")}</h4>
            <p>{station?.description}</p>
          </Col>
          <Col span={6} className="bigItem">
            <h4>{t("translation:type")}</h4>
            <p>{station?.type}</p>
          </Col>
          <Col span={6} className="bigItem">
            <h4>
              {t("translation:dns")} {t("translation:name")}
            </h4>
            <p>{station?.dnsName}</p>
          </Col>
          <Col span={6} className="bigItem">
            <h4>{t("translation:warehouse")}</h4>

            <button onClick={goToWarehouse} className="textButton">
              {station?.warehouseName}
            </button>
          </Col>
        </Row>
      </Box>
    </div>
  );
}
