import React, { useCallback } from "react";
import { Form, SubmitButton, Input, Select } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { newConnectionSchema } from "/app/src/schemas";
import { useTranslation } from "react-i18next";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { Connection } from "/app/src/models";
import TypeField from "./typeField";
import Box from "/app/src/components/generic/components/box";
import { settingService } from "/app/src/services";
import NewFormikTypeWrapper from "./connectionType/newFormikTypeWrapper";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

interface FormValues {
  name: string | undefined;
  type: string | undefined;
  url: string | undefined;
  authentication: string | undefined;
  username: string | undefined;
  password: string | undefined;
  token: string | undefined;
  login: string | undefined;
  tokenDuration: string | undefined;
  tokenDurationUnit: string | undefined;
  loginEndpoint: string | undefined;
  refreshEndpoint: string | undefined;
}

export default function NewConnection({
  toggleNew,
  addConnection,
}: {
  toggleNew: () => void;
  addConnection: (
    connection: Connection,
  ) => Promise<{ connection: Connection }>;
}) {
  const { t } = useTranslation();

  const getAuthOptions = useCallback((type: string) => {
    if (type === "REST") {
      return [
        { value: "Basic", label: "Basic" },
        { value: "No Auth", label: "No Auth" },
        { value: "OAuth1", label: "OAuth 1.0" },
        { value: "OAuth2", label: "OAuth 2.0" },
        {
          value: "TBA",
          label: "Token Based Authentication",
        },
      ];
    }
    if (type === "GraphQL") {
      return [
        { value: "No Auth", label: "No Auth" },
        { value: "Basic", label: "Basic" },
        {
          value: "TBA",
          label: "Token Based Authentication",
        },
      ];
    }
    if (type === "MSSQL" || type === "IBM2") {
      return [{ value: "Password", label: "Username/Password" }];
    }
    return [{ value: "No Auth", label: "No Auth" }];
  }, []);

  const newConnectionForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid, values }) => (
        <Form layout="vertical">
          <Row>
            <Col span={22}>
              <h3>
                {t("translation:create")} {t("translation:new_connection")}
              </h3>
            </Col>
            <Col span={2}>
              <IconBuilder
                icon="Close"
                color="#e00000"
                size={14}
                onClick={toggleNew}
                className="float-right"
              />
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={12}>
              <Form.Item name="name" label={t("translation:name")}>
                <Input suffix name="name" className="user" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <TypeField />
            </Col>
            <Col span={12}>
              <Form.Item
                name="authentication"
                label={t("translation:authentication")}
              >
                <Select
                  name="authentication"
                  size="large"
                  disabled={values.type === undefined}
                >
                  {getAuthOptions(values.type).map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="url" label={t("translation:address")}>
                <Input suffix name="url" className="url" size="large" />
              </Form.Item>
            </Col>
            <NewFormikTypeWrapper />
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={24}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || !isValid}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [getAuthOptions, t, toggleNew],
    );
  const initialFormValues: FormValues = {
    username: "",
    password: "",
    url: "",
    name: "",
    type: "REST",
    token: "",
    authentication: "",
    login: "",
    tokenDuration: "",
    tokenDurationUnit: "h",
    loginEndpoint: "",
    refreshEndpoint: "",
  };
  const createConnectionHandler = useCallback(
    async (values: FormValues, actions) => {
      await addConnection({
        name: values.name,
        type: values.type,
        url: values.url,
        authentication: values.authentication,
        username: values.username,
        password: values.password,
        token: values.token,
      })
        .then((resp) => {
          if (values.authentication === "TBA") {
            //need to add token duration and login setting
            settingService.createSingle({
              connectionId: resp.connection.id,
              type: "tokenDuration",
              value: `${values.tokenDuration}${values.tokenDurationUnit}`,
            });
            settingService.createSingle({
              connectionId: resp.connection.id,
              type: "login",
              value: values.login,
            });
          } else if (values.authentication === "OAuth2") {
            settingService.createSingle({
              connectionId: resp.connection.id,
              type: "login",
              value: values.loginEndpoint,
            });
            settingService.createSingle({
              connectionId: resp.connection.id,
              type: "refresh",
              value: values.refreshEndpoint,
            });
          }
          toggleNew();
        })
        .catch((errors) => {
          handleSubmissionErrors(errors, actions.setFieldError);
        });
    },
    [addConnection, toggleNew],
  );

  return (
    <Box>
      <Formik
        component={newConnectionForm}
        validationSchema={newConnectionSchema}
        initialValues={initialFormValues}
        enableReinitialize
        onSubmit={createConnectionHandler}
      />
    </Box>
  );
}
