import React, { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Form, SubmitButton, Input } from "formik-antd";
import { Setting } from "/app/src/models";
import { settingService } from "/app/src/services";
import { Col, Row } from "antd";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ColorPicker from "/app/src/components/generic/components/colorPicker";
import { handlePromiseError } from "/app/src/helpers/api";
import NextButton from "/app/src/components/NextUi/Button";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

interface FormValues {
  value?: string;
  name?: string;
  number?: string;
}

/**
 * Takes the form values and formats them for the API.
 * The value and number are combined into a JSON string.
 * @param values the form values
 * @returns the formatted form values
 */
function formatForm(values: FormValues) {
  const value = { color: values.value, value: values.number };
  return {
    name: values.name,
    value: JSON.stringify(value),
  };
}

/**
 * The Trendline component is used to edit or a delete a trendline
 * @param trendline the trendline to edit
 * @returns the edit trendline form
 */
export default function TrendLine({
  trendLine,
}: {
  trendLine: Setting & { color: string };
}) {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteTrendline } = useMutation({
    mutationFn: (trendLine: Setting) =>
      settingService.deleteSingle(trendLine.id),
    onSuccess: () => {
      queryClient.setQueryData(
        ["trendLines", trendLine.widgetId],
        (oldData: { settings: Setting[] }) => {
          return {
            settings: oldData.settings.filter(
              (trendLineData) => trendLineData.id !== trendLine.id,
            ),
          };
        },
      );
    },
  });

  const { mutateAsync: updateTrendline } = useMutation({
    mutationFn: (trendLine: Setting) =>
      settingService
        .updateSingle(trendLine.id, trendLine)
        .then(handlePromiseError),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["trendLines", trendLine.widgetId],
        (oldData: { settings: Setting[] }) => {
          return {
            settings: oldData.settings.map((trendLineData) =>
              trendLineData.id === trendLine.id
                ? response.setting
                : trendLineData,
            ),
          };
        },
      );
    },
  });

  /**
   * Handle the submit of the form. Update the trendline and close the color picker
   * @param values the form values
   */
  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await updateTrendline({
        id: trendLine.id,
        ...formatForm(values),
      });
    },
    [updateTrendline, trendLine],
  );

  /**
   * Update trendline form
   * @param props the formik props
   * @returns the form
   */
  const trendLineForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid, isSubmitting }) => (
        <Form>
          <Row justify="start" gutter={4}>
            <Col span={12}>
              <Form.Item name="name" hasFeedback={false}>
                <Input name="name" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="number" hasFeedback={false}>
                <Input name="number" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <ColorPicker />
            </Col>
            <Col span={4} offset={2}>
              <div className="float-right flex items-center">
                <SubmitButton
                  type="primary"
                  icon={
                    <IconBuilder
                      className="flex justify-center"
                      icon="Checkmark"
                      color={
                        !dirty || !isValid || isSubmitting
                          ? "#d9d9d9"
                          : "#ffffff"
                      }
                      size={16}
                    />
                  }
                  disabled={!dirty || !isValid || isSubmitting}
                />
                <NextButton
                  onClick={() => {
                    deleteTrendline(trendLine);
                  }}
                  color="red"
                  size="sm"
                  className="min-w-4"
                >
                  <IconBuilder icon="Undo" color="#ffffff" size={16} />
                </NextButton>
              </div>
            </Col>
          </Row>
        </Form>
      ),
      [deleteTrendline, trendLine],
    );
  return (
    <div className="trendline">
      <Formik
        initialValues={{
          value: trendLine.color,
          number: trendLine.value,
          name: trendLine.name,
        }}
        enableReinitialize
        component={trendLineForm}
        validationSchema={simpleSchemaBuilder([
          { name: "name", type: "string", required: true },
          { name: "number", type: "number", required: true },
        ])}
        onSubmit={handleSubmit}
      />
    </div>
  );
}
