import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { orderService } from "/app/src/services";
import { FormikProps } from "formik";
import DynamicFieldsForm from "./dynamicFieldsForm";
import Material from "./material";
import { useTranslation } from "react-i18next";
import { OrderLine } from "/app/src/models";
import NextButton from "/app/src/components/NextUi/Button";

interface FormValues {
  issueTo: string;
  qualification: string;
  facility: string;
  notes: string;
}
export default function SingleOrder({
  orderId,
  cancelOrder,
  formRef,
}: {
  orderId: string;
  cancelOrder: () => void;
  formRef: React.RefObject<FormikProps<FormValues>>;
}) {
  const { t } = useTranslation();
  const [orderlines, setOrderlines] = useState<{ [key: string]: number }>({});
  //for tracking which material is being processed - for automatically opening correct collapse
  const [activeMaterial, setActiveMaterial] = useState(0);
  const [orderName, setOrderName] = useState("");

  const changeMaterial = (index: number) => {
    setActiveMaterial(index);
  };
  const groupOrderlines = (lines: OrderLine[]) => {
    //dictionary where the keys are the material names, and the values
    //are an array of the serial numbers
    const groupedByMaterial: { [key: string]: number } = {};
    lines.forEach((line) => {
      //check if the key exists, if not add it
      if (line.materialName) {
        if (!(line.materialName in groupedByMaterial)) {
          groupedByMaterial[line.materialName] = line.quantity as number;
        } else {
          groupedByMaterial[line.materialName] += line.quantity as number;
        }
      }
    });
    return groupedByMaterial;
  };

  useEffect(() => {
    orderService.getSingle(orderId).then((response) => {
      setOrderlines(groupOrderlines(response.order.order_lines));
      setOrderName(response.order.name);
    });
  }, [orderId]);

  return (
    <div>
      <Row className="order" align="middle">
        <Col
          xs={{ span: 18, order: 1 }}
          sm={{ span: 18, order: 1 }}
          md={{ span: 18, order: 1 }}
          lg={{ span: 20, order: 1 }}
        >
          <div className="title" style={{ float: "left" }}>
            {t("translation:order_name")}:<h3>{orderName}</h3>
          </div>
        </Col>
        <Col
          xs={{ span: 6, order: 2 }}
          sm={{ span: 6, order: 2 }}
          md={{ span: 6, order: 2 }}
          lg={{ span: 4, order: 2 }}
        >
          <div className="batchControlButtons">
            <NextButton
              size="md"
              className="bg-[#a12121] text-white"
              onClick={cancelOrder}
            >
              {t("translation:cancel")}
            </NextButton>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <DynamicFieldsForm formRef={formRef} />
        </Col>
      </Row>
      <Row className="material">
        <Col span={24}>
          <Row className="materialHeader">
            <Col span={2}>
              <p style={{ marginBottom: "0", fontSize: "10px" }}>
                {t("translation:material")}:
              </p>
            </Col>
            <Col span={22}>
              <p
                style={{ float: "right", marginBottom: "0", fontSize: "10px" }}
              >
                {t("translation:quantity")}:
              </p>
            </Col>
          </Row>
          <Row>
            {Object.entries(orderlines).map(([key, value], index) => (
              <Material
                key={key}
                changeMaterial={changeMaterial}
                material={key}
                index={index}
                quantity={value}
                activeMaterial={activeMaterial}
              />
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
}
