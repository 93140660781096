import { Helmet } from "react-helmet";
import { Route, Routes, useParams } from "react-router-dom";
import { integrationService } from "/app/src/services";
import { Row, Col } from "antd";
import { useQuery } from "@tanstack/react-query";
import { Integration as IntegrationType, App } from "/app/src/models";
import IntegrationMenu from "./_menu";
import DataPullLogs from "./logs";
import Mappings from "/app/src/components/generic/components/apps/data/mappings";
import Settings from "./settings";
import Test from "./test";
import Confirmation from "./confirmation";
import Manual from "./manual";
import Run from "./run";

/**
 * Component for Data Pull integration. Shows settings, logs
 * @param param0 props containing the data pull app
 */
export default function DataPull({ app }: { app: App }) {
  const { integrationId } = useParams();

  const { data: integration, isFetching } = useQuery({
    queryKey: ["integration", Number(integrationId)],
    queryFn: () => integrationService.getSingle(integrationId),
    enabled: Boolean(integrationId),
    initialData: { integration: {} },
    select: (data: { integration: IntegrationType }) => data.integration,
  });
  return (
    <div className="integration">
      <Helmet>
        <title>{integration?.name}</title>
      </Helmet>
      {isFetching ? (
        "Loading..."
      ) : (
        <Row gutter={20}>
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            lg={{ span: 3, order: 1 }}
          >
            <IntegrationMenu app={app} integration={integration} />
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            lg={{ span: 21, order: 2 }}
          >
            {integration.id && (
              <Routes>
                <Route
                  path="/"
                  element={<Settings integration={integration} />}
                />
                <Route
                  path="runs"
                  element={<DataPullLogs integration={integration} />}
                />
                <Route
                  path="mappings"
                  element={<Mappings integration={integration} />}
                />
                <Route
                  path="test"
                  element={<Test integration={integration} />}
                />
                <Route
                  path="confirmation"
                  element={<Confirmation integration={integration} />}
                />
                <Route
                  path="manual"
                  element={<Manual integration={integration} />}
                />
                <Route path="run" element={<Run integration={integration} />} />
              </Routes>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}
