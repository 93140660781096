import { useCallback, useEffect, useState } from "react";
import { Row, Col, Collapse } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  RightOutlined,
  DownOutlined,
} from "@ant-design/icons";

import { processService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { useNumberId } from "/app/src/hooks/useNumberId";
import NextButton from "/app/src/components/NextUi/Button";

/**
 * Serial number list component. Displays a list of serial numbers with icons to indicate selection status.
 */
const SerialNumberList = ({
  serialNumberGroupings,
  selectedSerialNumbers,
  toggleSelectedSerialNumber,
}) => {
  const setSelectSerialNumberOne = useCallback(
    (number) => {
      toggleSelectedSerialNumber(number, 1);
    },
    [toggleSelectedSerialNumber],
  );

  const setSelectSerialNumberZero = useCallback(
    (number) => {
      toggleSelectedSerialNumber(number, 0);
    },
    [toggleSelectedSerialNumber],
  );

  // Determine the appropriate icon and its click handler
  const getIconProps = (number) => {
    const selected = selectedSerialNumbers.find(
      (element) => element.serialNumber === number,
    );
    const IconComponent =
      selected?.quantity === 1 ? CheckOutlined : CloseOutlined;
    const iconColorClass =
      selected?.quantity === 1 ? "text-green-500" : "text-red-500";

    /**
     * Click handler for the icon. If the serial number is already selected, deselect it.
     */
    const handleClick = () => {
      if (selected?.quantity === 1) {
        setSelectSerialNumberZero(number);
      } else {
        setSelectSerialNumberOne(number);
      }
    };

    return { IconComponent, iconColorClass, handleClick };
  };

  return (
    <>
      {serialNumberGroupings.numbers.map((number) => {
        const { IconComponent, iconColorClass, handleClick } =
          getIconProps(number);
        return (
          <div key={number} className="flex items-center space-x-2">
            <span className="text-lg">{number}</span>
            <IconComponent
              onClick={handleClick}
              className={`text-2xl cursor-pointer ${iconColorClass}`}
            />
          </div>
        );
      })}
    </>
  );
};

export default function SerialNumbers({
  index,
  serialNumberGroupings,
  currentGrouped,
  setGrouping,
}: {
  index: number;
  currentGrouped: number;
  setGrouping: (grouping: number) => void;
  serialNumberGroupings: { name: string; numbers: number[] };
}) {
  const { t } = useTranslation();
  const { processId } = useNumberId();
  const [status, setStatus] = useState("queued");
  const [title, setTitle] = useState("");
  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState<
    { quantity: number; serialNumber: number }[]
  >([]);
  const [skippedNumbers, setSkippedNumbers] = useState<
    { quantity: number; serialNumber: number }[]
  >([]);
  const [currentProcess, setCurrentProcess] = useState<number | undefined>(
    undefined,
  );
  const [selectedCount, setSelectedCount] = useState(0);
  const [activeKey, setActiveKey] = useState(0);
  useEffect(() => {
    if (index === currentGrouped) {
      if (serialNumberGroupings.name) {
        setActiveKey(1);
      } else {
        // Do not set as active if there is no location
        setGrouping(index + 1);
      }
    } else {
      setActiveKey(0);
    }
  }, [currentGrouped, setGrouping, serialNumberGroupings, index]);

  useEffect(() => {
    if (currentProcess) {
      if (
        skippedNumbers.length > 0 &&
        selectedSerialNumbers.length - skippedNumbers.length === 0
      ) {
        setStatus("skipped");
      } else if (selectedSerialNumbers.length - skippedNumbers.length === 0) {
        setStatus("done");
      }
    }
  }, [skippedNumbers, selectedSerialNumbers, currentProcess]);

  useEffect(() => {
    const length = serialNumberGroupings.numbers.length;
    const numbers = [];
    for (let i = 0; i < length; i++) {
      numbers.push({
        serialNumber: serialNumberGroupings.numbers[i],
        quantity: 1,
      });
    }
    setSelectedSerialNumbers(numbers);
    setSelectedCount(length);
  }, [serialNumberGroupings]);
  const addNumber = useCallback((number: number, status: number) => {
    setSelectedSerialNumbers((numbers) => [
      ...numbers,
      { serialNumber: number, quantity: status },
    ]);
  }, []);

  const removeNumber = useCallback((number: number) => {
    setSelectedSerialNumbers((lines) =>
      lines.filter((c) => c.serialNumber !== number),
    );
  }, []);

  const toggleSelectedSerialNumber = useCallback(
    (number: number, status: number) => {
      removeNumber(number);
      addNumber(number, status);
      setSelectedCount((prevCount) => prevCount + (status ? 1 : -1));
    },
    [addNumber, removeNumber],
  );

  const sendToPowerPick = useCallback(
    (line: { quantity: number; serialNumber: number }, operation: string) => {
      if (operation === "skip") {
        line["quantity"] = 0;
      }

      return processService.updateSingle(processId as string, {
        lines: [line],
      });
    },
    [processId],
  );
  const confirm = useCallback(
    async (operation: string) => {
      //process orderlines here,
      //if no errors then go next grouped
      setStatus("processing");
      await Promise.all(
        selectedSerialNumbers.map(async (line) => {
          setCurrentProcess(line["serialNumber"]);
          const response = await sendToPowerPick(line, operation);
          // if any of the serial numbers are deselected(skipped),
          // the ui should show which got skipped
          if (line["quantity"] === 0) {
            if (response) {
              setSkippedNumbers((skippedNumbers) => [...skippedNumbers, line]);
            }
          } else {
            removeNumber(line["serialNumber"]);
          }
        }),
      );
      setGrouping(index + 1);
    },
    [index, removeNumber, selectedSerialNumbers, sendToPowerPick, setGrouping],
  );

  const confirmSkip = useCallback(() => {
    confirm("skip");
  }, [confirm]);

  const confirmConfirm = useCallback(() => {
    confirm("confirm");
  }, [confirm]);

  useEffect(() => {
    setTitle(getGroupingTitle(serialNumberGroupings.numbers));
  }, [serialNumberGroupings]);

  switch (status) {
    case "done":
      return null;

    case "processing":
      return (
        <Row align="middle">
          <Col span={24} className="processing">
            <Col span={20}>
              {t("translation:powerpick_is_processing")}{" "}
              {currentProcess ? currentProcess : "..."}
            </Col>
          </Col>
        </Row>
      );

    case "skipped":
      return (
        <Col span={24} className="skipped">
          <Row align="middle">
            <Col span={20}>{`Skipped ${title}`}</Col>
          </Row>
        </Col>
      );

    case "queued":
      return (
        <Row align="middle">
          <Col span={24}>
            <Collapse
              activeKey={activeKey}
              className={
                activeKey === 1 ? "serialNumbersSelected" : "serialNumbers"
              }
            >
              <Collapse.Panel
                showArrow={false}
                className="panel"
                header={
                  <Row gutter={[0, 15]}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 16 }}
                      lg={{ span: 18 }}
                    >
                      {activeKey === 1 ? <DownOutlined /> : <RightOutlined />}
                      <span style={{ fontSize: "16px" }}>
                        {title}{" "}
                        <span style={{ fontSize: "10px;", fontWeight: "bold" }}>
                          ({selectedCount} selected)
                        </span>{" "}
                        -{" "}
                        {serialNumberGroupings.name ? (
                          <span style={{ fontSize: "14px" }}>
                            {serialNumberGroupings.name}
                          </span>
                        ) : (
                          <span style={{ fontSize: "14px", color: "red" }}>
                            not allocated
                          </span>
                        )}
                      </span>
                    </Col>
                    <Col
                      style={{
                        marginLeft: "auto",
                        marginRight: "0",
                      }}
                    >
                      {activeKey === 1 && serialNumberGroupings.name ? (
                        <>
                          <NextButton
                            onClick={confirmSkip}
                            className="mr-[10px]"
                            size="sm"
                          >
                            {t("translation:skip")}
                          </NextButton>
                          <NextButton
                            onClick={confirmConfirm}
                            color="primary"
                            size="sm"
                          >
                            {t("translation:confirm")} {selectedCount}
                          </NextButton>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                }
                key="1"
              >
                {selectedSerialNumbers.length > 0 && (
                  <Row gutter={[10, 0]}>
                    <SerialNumberList
                      serialNumberGroupings={serialNumberGroupings}
                      selectedSerialNumbers={selectedSerialNumbers}
                      toggleSelectedSerialNumber={toggleSelectedSerialNumber}
                    />
                  </Row>
                )}
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      );
    default:
      return null;
  }
}

function getGroupingTitle(serialNumbers: number[]) {
  if (serialNumbers.length > 1) {
    const string = `${serialNumbers[0]} - ${
      serialNumbers[serialNumbers.length - 1]
    }`;
    return string;
  } else {
    return String(serialNumbers[0]);
  }
}
