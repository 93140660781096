import { Formik, FormikProps } from "formik";
import { Connection } from "/app/src/models";
import { useCallback } from "react";
import { Form, Input, InputNumber, Select, SubmitButton } from "formik-antd";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { simpleSchemaBuilder } from "/app/src/helpers";
import useSetting from "../setting";

interface FormValues {
  tokenDuration?: string;
  tokenDurationUnit?: string;
  login?: string;
}

/**
 * Displays the form for setting the token duration and login for token based authentication
 */
export default function TokenBasedAuthentication({
  connection,
}: {
  connection: Connection;
}) {
  const {
    settingValue: tokenDuration,
    createUpdateSetting: createUpdateTokenDuration,
  } = useSetting({
    connectionId: connection.id,
    type: "tokenDuration",
  });

  const { settingValue: login, createUpdateSetting: createUpdateLogin } =
    useSetting({
      connectionId: connection.id,
      type: "login",
    });

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await createUpdateTokenDuration({
        type: "tokenDuration",
        connectionId: connection.id,
        value: `${values.tokenDuration}${values.tokenDurationUnit}`,
      });
      return createUpdateLogin({
        type: "login",
        connectionId: connection.id,
        value: values.login,
      });
    },

    [connection.id, createUpdateLogin, createUpdateTokenDuration],
  );
  const { t } = useTranslation();
  const loginForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <h3>{t("translation:token_settings")}</h3>
          <Row justify="start" gutter={16}>
            <Col span={8}>
              <Form.Item name="login" label={t("translation:login")}>
                <Input name="login" size="large" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                name="tokenDuration"
                label={t("translation:token_duration")}
              >
                <InputNumber
                  name="tokenDuration"
                  min={0}
                  max={1000000}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                name="tokenDurationUnit"
                label={t("translation:unit_of_time")}
              >
                <Select name="tokenDurationUnit" size="large">
                  {[
                    { value: "m", label: "minutes" },
                    { value: "h", label: "hours" },
                    { value: "d", label: "days" },
                  ].map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || !isValid}
                style={{ marginTop: "30px" }}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );
  return (
    <Formik
      component={loginForm}
      initialValues={{
        login,
        tokenDuration: tokenDuration?.slice(0, -1),
        tokenDurationUnit: tokenDuration?.slice(-1),
      }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={simpleSchemaBuilder([
        { name: "login", type: "url", required: true },
      ])}
    />
  );
}
