import { useCallback } from "react";
import Connection from "./connection";
import { connectionService } from "/app/src/services";
import NewConnection from "./connection/newConnection";
import { useToggle } from "/app/src/hooks";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";
import { useTranslation } from "react-i18next";
import { Connection as ConnectionType } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "../../helpers/api";

/**
 * Component to display the connections that aren't PowerPick Web Services or PowerPick SQL
 */
export default function Connections() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [newConnection, toggleNewConnection] = useToggle(false);

  /**
   * Handle click event on new connection button
   */
  const handleClickEvent = (event: React.MouseEvent): void => {
    event.preventDefault();
    toggleNewConnection();
  };

  const connectionsQuery = useQuery({
    queryKey: ["connections"],
    queryFn: () =>
      connectionService.getAll(
        buildParams({ type: "[nor]PowerPick SQL;PowerPick Web Services" }),
      ),
    initialData: { connections: [] },
  });

  const { mutateAsync: createConnectionQuery } = useMutation({
    mutationFn: (connection: ConnectionType) =>
      connectionService.createSingle(connection).then(handlePromiseError),
    onSuccess: (data) => {
      if ("connection" in data) {
        queryClient.setQueryData(
          ["connections"],
          (oldData: { connections: ConnectionType[] }) => {
            return { connections: [...oldData.connections, data.connection] };
          },
        );
      }
    },
  });

  /**
   * Create a new connection using useMutation hook
   * @param connection Connection to create
   */
  const createConnection = useCallback(
    (connection: ConnectionType) => {
      return createConnectionQuery(connection);
    },
    [createConnectionQuery],
  );

  const { mutateAsync: updateConnectionQuery } = useMutation({
    mutationFn: (connection: ConnectionType) =>
      connectionService
        .updateSingle(connection.id, connection)
        .then(handlePromiseError),
    onSuccess: (data) => {
      if ("connection" in data) {
        queryClient.setQueryData(
          ["connections"],
          (oldData: { connections: ConnectionType[] }) => {
            const index = oldData.connections.findIndex(
              (connection) => connection.id === data.connection.id,
            );
            const copy = oldData.connections.slice();
            copy[index] = data.connection;
            return { connections: copy };
          },
        );
      }
    },
  });

  /**
   * Update a connection using useMutation hook
   * @param connection Connection to update
   */
  const updateConnection = (connection: ConnectionType) => {
    return updateConnectionQuery(connection);
  };

  const { mutateAsync: deleteConnectionQuery } = useMutation({
    mutationFn: (connection: ConnectionType) =>
      connectionService.deleteSingle(connection.id),
    onSuccess: () => {
      queryClient.invalidateQueries(["connections"]);
    },
  });

  /**
   * Delete a connection using useMutation hook
   * @param connection Connection to delete
   */
  const deleteConnection = (connection: ConnectionType) => {
    return deleteConnectionQuery(connection);
  };

  return (
    <div>
      <h1>{t("translation:connections")}</h1>
      {connectionsQuery.data.connections.map((connection) => {
        return (
          <Connection
            key={connection.id}
            connection={connection}
            removable
            updateConnection={updateConnection}
            deleteConnection={deleteConnection}
            editable={
              connection.type === "Hue Remote" ||
              connection.type === "Hue Local"
                ? false
                : true
            }
            testable={connection.isTestable}
          />
        );
      })}
      {newConnection ? (
        <NewConnection
          toggleNew={toggleNewConnection}
          addConnection={createConnection}
        />
      ) : (
        <IconBuilder
          icon="PlusCircle"
          color="#82878e"
          onClick={handleClickEvent}
          size={48}
          className="block ml-auto mr-auto"
        />
      )}
    </div>
  );
}
