import React from "react";
import { PowerPickTimeZoneToLocal } from "/app/src/components/generic/formatting/dateTime";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Descriptions from "/app/src/components/generic/components/descriptions";
import DynamicFields from "/app/src/components/explorer/generic/dynamicFields";
import LocationContents from "./locationContents";
import { useQuery } from "@tanstack/react-query";
import {
  Material as MaterialType,
  MaterialProperty as MaterialPropertyType,
} from "/app/src/models";
import { materialService, materialPropertyService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import Box from "/app/src/components/generic/components/box";

export default function Material() {
  const { t } = useTranslation();
  const { materialId } = useParams();

  const { data: material } = useQuery({
    queryKey: ["material", materialId],
    queryFn: () => {
      return materialService.getSingle(materialId);
    },
    initialData: { material: {} },
    select: (data: { material: MaterialType }) => {
      return data.material;
    },
    enabled: Boolean(materialId),
  });

  const { data: materialProperty } = useQuery({
    queryKey: ["materialProperty", material.materialPropertyId],
    queryFn: () => {
      return materialPropertyService.getSingle(material.materialPropertyId);
    },
    initialData: { material_property: {} },
    select: (data: { material_property: MaterialPropertyType }) => {
      return data.material_property;
    },
    enabled: Boolean(material.materialPropertyId),
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{material.name}</title>
      </Helmet>
      <div>
        <Box>
          <h1>{material.name}</h1>
          <div className="bigItems">
            <div className="bigItem">
              <h4>{t("translation:created")}</h4>
              <span>
                <PowerPickTimeZoneToLocal
                  date={material.creationDate}
                  format={"MMM YYYY"}
                />
              </span>
            </div>
            <div className="bigItem">
              <h4>{t("translation:blocked")}</h4>
              <span>{material.isBlocked ? "Yes" : "No"}</span>
            </div>
            <div className="bigItem">
              <h4>{t("translation:current_quantity")}</h4>
              <span>{material.currentQuantity}</span>
            </div>

            {materialProperty && (
              <div className="bigItem">
                <h4>{t("translation:property")}</h4>
                <span>
                  <Link
                    to={`/explorer/material_properties/${materialProperty.id}`}
                  >
                    {materialProperty.name}
                  </Link>
                </span>
              </div>
            )}
          </div>
        </Box>
        <Box>
          <h3>{t("translation:information_fields")}</h3>
          <Descriptions
            items={[
              { label: t("translation:info1"), value: material.Info1 },
              { label: t("translation:info2"), value: material.Info2 },
              { label: t("translation:info3"), value: material.Info3 },
              { label: t("translation:info4"), value: material.Info4 },
              { label: t("translation:info5"), value: material.Info5 },
            ]}
          />

          <DynamicFields object={material} baseTable="Materialbase" />
        </Box>
        <LocationContents material={material} />
      </div>
    </div>
  );
}
