import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { storageRuleService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { StorageRule as StorageRuleType } from "/app/src/models";
import Box from "/app/src/components/generic/components/box";

export default function StorageRule() {
  const { t } = useTranslation();
  const { storageRuleId } = useParams();

  //Get the Single StorageRule from URL
  const { data: storageRule } = useQuery({
    queryKey: ["storageRule", storageRuleId],
    queryFn: () => {
      return storageRuleService.getSingle(storageRuleId);
    },
    initialData: { storage_rule: {} },
    select: (data: { storage_rule: StorageRuleType }) => {
      return data.storage_rule;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>
          {storageRule?.description
            ? storageRule?.description
            : storageRule?.id}
        </title>
      </Helmet>
      {storageRule?.id ? (
        <Box>
          <h1>
            {storageRule?.description
              ? storageRule?.description
              : storageRule?.id}
          </h1>
          <div className="bigItems">
            <div className="bigItem">
              <h4>{t("translation:required_capacity")}</h4>
              <span>{storageRule?.requiredCapacity}</span>
            </div>
            <div className="bigItem">
              <h4>{t("translation:is_default_bin")}</h4>
              <span>{storageRule?.isDefaultBin ? "Yes" : "No"}</span>
            </div>
            <div className="bigItem">
              <h4>{t("translation:min_stock_per_bin")}</h4>
              <span>{storageRule?.minStockPerBin}</span>
            </div>
          </div>
        </Box>
      ) : (
        <div />
      )}
    </div>
  );
}
