import React from "react";
import { Modal, Button } from "antd";
import { settingService } from "/app/src/services";
import { useTranslation } from "react-i18next";
const { confirm } = Modal;

export default function DeleteForm(props) {
  const { t } = useTranslation();
  return (
    <Button
      type="danger"
      className="btn-delete"
      onClick={() =>
        ShowConfirm(
          t("delete"),
          t("do_not_delete"),
          t("confirm_delete_channel"),
          props.settingId,
          props.refresh,
        )
      }
    >
      {t("delete")} {t("channel")}
    </Button>
  );
}

function ShowConfirm(okText, cancelText, titleText, settingId, refresh) {
  confirm({
    okText: okText,
    okButtonProps: { type: "danger" },
    cancelText: cancelText,
    title: titleText,
    onOk() {
      //refresh the list of channels if channel successfully removed
      settingService.deleteSingle(settingId).then((response) => {
        if (response.deleted === "true") {
          refresh();
        }
      });
    },
    onCancel() {},
  });
}
