import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Row, Col } from "antd";
import { dynamicFieldService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { DynamicField as DynamicFieldType } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import Box from "/app/src/components/generic/components/box";

export default function SingleDynamicField() {
  const { fieldId } = useParams();
  const { t } = useTranslation();

  // Get the single DynamicField from URL
  const { data: field } = useQuery({
    queryKey: ["dynamicField", fieldId],
    queryFn: () => {
      return dynamicFieldService.getSingle(fieldId);
    },
    initialData: { dynamic_field: {} },
    select: (data: { dynamic_field: DynamicFieldType }) => {
      return data.dynamic_field;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{field?.name}</title>
      </Helmet>
      <Box>
        <Row>
          <Col span={6} className="bigItem">
            <h4>{t("translation:field_name")}</h4>
            <span>{field?.name}</span>
          </Col>
        </Row>
        <Row>
          <Col span={6} className="bigItem">
            <h4>{t("translation:field")}</h4>
            <p>{field?.field}</p>
          </Col>
          <Col span={6} className="bigItem">
            <h4>{t("translation:base_table")}</h4>
            <p>{field?.baseTable}</p>
          </Col>
          <Col span={6} className="bigItem">
            <h4>{t("translation:in_history")}</h4>
            <p>
              {field?.usedInHistory === 1
                ? t("translation:yes")
                : t("translation:no")}
            </p>
          </Col>
          <Col span={6} className="bigItem">
            <h4>{t("translation:nullable")}</h4>
            <p>
              {field?.nullable === 1
                ? t("translation:yes")
                : t("translation:no")}
            </p>
          </Col>
          <Col span={6} className="bigItem">
            <h4>{t("translation:default_value")}</h4>
            <p>{field?.defaultValue ? field?.defaultValue : "Null"}</p>
          </Col>
        </Row>
      </Box>
    </div>
  );
}
