import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { Integration } from "/app/src/models";
import { integrationService } from "/app/src/services";
import { useCallback, useState } from "react";
import NextButton from "/app/src/components/NextUi/Button";
import Box from "/app/src/components/generic/components/box";
import NextTextarea from "/app/src/components/NextUi/Textarea";
import { isJSON } from "/app/src/helpers";

/**
 * Component to display the manual run component for a single Data Pull Integration
 */
export default function Run({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const [runResponse, setRunResponse] = useState<string | null>(null);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>("");

  /**
   * Test the integration
   */
  const handleRunClick = useCallback(async () => {
    setIsRunning(true);
    setRunResponse(null);
    let body = {};
    if (!isJSON(textValue)) {
      setRunResponse("Error:\nInvalid JSON");
      setIsRunning(false);
      return;
    }
    body = JSON.parse(textValue);

    try {
      const response = await integrationService.runIntegration(
        integration.id,
        body,
      );
      if (response?.data) {
        setRunResponse(`Data Pulled:\n${JSON.stringify(response?.data)}`);
      } else {
        setRunResponse(`Result:\n${response?.message}`);
      }
    } catch (error) {
      setRunResponse(`Error:\n${error.message}`);
    }
    setIsRunning(false);
  }, [integration.id, textValue]);

  const handleTextChange = useCallback((e) => {
    setTextValue(e.target.value);
  }, []);

  return (
    <Box>
      <NextTextarea
        className="mt-4 mb-4"
        value={textValue}
        onChange={handleTextChange}
        maxRows={20}
      />
      <Row gutter={16}>
        <Col span={12}>
          {t("translation:manual_run_tip")}
          <br />
          {`/api/integrations/${integration.id}/run`}
        </Col>
        <Col offset={4} span={4}></Col>
        <Col span={4}>
          <NextButton
            color="primary"
            fullWidth
            onClick={handleRunClick}
            isDisabled={isRunning}
            className="mt-4"
          >
            {t("translation:run")}
          </NextButton>
        </Col>
      </Row>
      {runResponse && <p>{runResponse}</p>}
    </Box>
  );
}
