import {
  makeAPIRequest,
  genericGetSingle,
  genericCreateSingle,
  genericDeleteSingle,
  genericGetAll,
  genericUpdateSingle,
} from "../helpers/apiCall";
import axios from "axios";
import { User, Login, Refresh, Reset } from "../models";
import { Error } from "../types";

/**
 * Gets all users
 * @param params URLSearchParams
 * @returns Promise<{ users: User[] }>
 */
function getAll(params: URLSearchParams = null): Promise<{ users: User[] }> {
  return genericGetAll("users", params);
}

/**
 * Gets a single user
 * @param userId number | string
 * @returns Promise<{ user: User }>
 */
function getSingle(userId: number | string): Promise<{ user: User }> {
  return genericGetSingle("users", userId);
}

/**
 * Create a new user
 * @param user { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ user: User } | Error>
 */
function createSingle(user: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<{ user: User } | Error> {
  return genericCreateSingle("users", user);
}

/**
 * Update a single user
 * @param id number | string
 * @param user { [key: string]: string | number | boolean | Date | undefined}
 * @returns Promise<{ user: User } | Error>
 */
function updateSingle(
  id: number | string,
  user: { [key: string]: string | number | boolean | Date | undefined },
): Promise<{ user: User } | Error> {
  return genericUpdateSingle("users", id, user);
}

/**
 * Delete a single user
 * @param id number | string
 * @returns Promise<{ deleted: boolean } | { error: string }>
 */
function deleteSingle(
  id: number | string,
): Promise<{ deleted: boolean } | { error: string }> {
  return genericDeleteSingle("users", id);
}

function refresh(refreshToken: string): Promise<Refresh> {
  //all other api calls use the same authentication header
  return axios({
    url: "/api/users/refresh",
    headers: {
      Authorization: `Bearer ${refreshToken}`,
      "Content-Type": "application/json",
    },
    method: "GET",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function login(details: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<Login> {
  return makeAPIRequest({ url: "/users/login", body: details, method: "post" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

function reset(search: {
  [key: string]: string | number | boolean | Date | undefined;
}): Promise<Reset> {
  return makeAPIRequest({ url: "/users/reset", body: search, method: "post" })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Gets the count of users
 * @param params URLSearchParams
 * @returns Promise<{ count: number }>
 */
function getCount(params: URLSearchParams = null): Promise<{ count: number }> {
  params?.append("countOnly", "true");
  return genericGetAll("users", params);
}

export const userService = {
  getAll,
  getSingle,
  createSingle,
  updateSingle,
  deleteSingle,
  refresh,
  reset,
  login,
  getCount,
};
