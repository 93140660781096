import { useTranslation } from "react-i18next";
import { iField } from "/app/src/models";
import { useCallback } from "react";
import { Form, SubmitButton } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { Col, Row } from "antd";
import MappingType from "./mappingType";
import DynamicField from "./dynamicFields/dynamicFields";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

interface FieldFormValues {
  mappingType: string;
  value?: string;
  value1?: string;
}

export default function Field({ iField }: { iField?: iField }) {
  const { t } = useTranslation();

  const updateField: (props: FormikProps<FieldFormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isSubmitting, values }) => (
        <Form layout="vertical">
          <Row justify="end" className="flex items-center">
            <Col className="pl-4" span={8}>
              <MappingType />
            </Col>
            <Col className="pl-4" span={12}>
              <DynamicField status={values.mappingType} />
            </Col>
            <Col span={4}>
              <Row justify="end">
                <SubmitButton
                  disabled={!dirty || isSubmitting}
                  loading={isSubmitting}
                >
                  {t("translation:save")}
                </SubmitButton>
              </Row>
            </Col>
          </Row>
        </Form>
      ),
      [t],
    );

  //logging the values callback function
  const onSubmit = useCallback(() => {
    return;
  }, []);

  return (
    <Row className="pt-6 items-center">
      <Col span={6}>
        <div className="flex justify-between">
          <span>{t("translation:order_name")}</span>
          <span>
            <IconBuilder icon="ArrowRight" />
          </span>
        </div>
      </Col>
      <Col span={18}>
        <Formik
          component={updateField}
          initialValues={{
            mappingType: "Not Mapped",
          }}
          onSubmit={onSubmit}
        />
      </Col>
    </Row>
  );
}
