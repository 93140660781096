import { Col } from "antd";
import { Form, Input, Select, InputNumber } from "formik-antd";
import { useTranslation } from "react-i18next";
import PasswordField from "/app/src/components/generic/components/passwordField";

/**
 * Component to manage displaying the form fields related to the connection type
 */
export default function ConnectionType({
  includeAuth,
  includeDatabase,
  hidePassword,
  hideToken,
  includeLogin = false,
  includeTokenDuration = false,
  includeEndpointSetting = false,
}: {
  includeAuth: boolean;
  includeDatabase: boolean;
  hidePassword: boolean;
  hideToken: boolean;
  includeLogin?: boolean;
  includeTokenDuration?: boolean;
  includeEndpointSetting?: boolean;
}) {
  const { t } = useTranslation();

  if (includeAuth) {
    return (
      <>
        <Col span={12}>
          <Form.Item name="username" label={t("translation:username")}>
            <Input suffix name="username" className="user" size="large" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="password" label={t("translation:password")}>
            <PasswordField name="password" passwordExists={hidePassword} />
          </Form.Item>
        </Col>
        {includeDatabase && (
          <Col span={12}>
            <Form.Item name="token" label={t("translation:database")}>
              <PasswordField name="token" passwordExists={hideToken} />
            </Form.Item>
          </Col>
        )}
        {includeLogin && (
          <Col span={12}>
            <Form.Item name="login" label={t("translation:login")}>
              <Input name="login" className="login" size="large" />
            </Form.Item>
          </Col>
        )}
        {includeTokenDuration && (
          <>
            <Col span={3}>
              <Form.Item
                name="tokenDuration"
                label={t("translation:token_duration")}
              >
                <InputNumber
                  name="tokenDuration"
                  min={0}
                  max={1000000}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                name="tokenDurationUnit"
                label={t("translation:unit_of_time")}
              >
                <Select name="tokenDurationUnit" size="large">
                  {[
                    { value: "m", label: "minutes" },
                    { value: "h", label: "hours" },
                    { value: "d", label: "days" },
                  ].map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
        {includeEndpointSetting && (
          <>
            <Col span={12}>
              <Form.Item
                name="loginEndpoint"
                hasFeedback={false}
                label={t("translation:login_path")}
              >
                <Input suffix name="loginEndpoint" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="refreshEndpoint"
                hasFeedback={false}
                label={t("translation:refresh_path")}
              >
                <Input suffix name="refreshEndpoint" size="large" />
              </Form.Item>
            </Col>
          </>
        )}
      </>
    );
  }
}
