import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { kitService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Kit as KitType } from "/app/src/models";
import Box from "/app/src/components/generic/components/box";

export default function Kit() {
  const { t } = useTranslation();
  const { kitId } = useParams();

  //Get the Single Kit from URL
  const { data: kit } = useQuery({
    queryKey: ["kit", kitId],
    queryFn: () => {
      return kitService.getSingle(kitId);
    },
    initialData: { kit: {} },
    select: (data: { kit: KitType }) => {
      return data.kit;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{kit?.name}</title>
      </Helmet>
      {kit && (
        <Box>
          <h1>{kit?.name}</h1>
          <div className="bigItems">
            <div className="bigItem">
              <h4>{t("translation:target")}</h4>
              <span>{kit?.target}</span>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
}
