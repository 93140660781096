import { useTranslation } from "react-i18next";
import {
  Integration,
  Mapping,
  ReportColumnType,
  Setting,
} from "/app/src/models";
import { useCallback } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Cascader, Form, Select, SubmitButton } from "formik-antd";
import { cascadeReportColumnTypes } from "/app/src/helpers/cascadeReportColumnTypes";
import { simpleSchemaBuilder } from "/app/src/helpers";
import NextButton from "/app/src/components/NextUi/Button";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

interface PrimaryMappingFormValues {
  mappingKey?: [string, number];
  sqlColumnName?: number;
}

/**
 *
 * @param param0 integration
 * @returns PrimaryMapping component
 */
export default function JoinCondition({
  integration,
  joinCondition,
  updateJoinCondition,
  deleteJoinCondition,
  reportColumnTypes,
  mappings,
}: {
  integration: Integration;
  joinCondition: Setting;
  updateJoinCondition: (values: Setting) => Promise<void>;
  deleteJoinCondition: (id: number) => Promise<{
    setting: {
      id: number;
    };
  }>;
  reportColumnTypes: ReportColumnType[];
  mappings: Mapping[];
}) {
  const { t } = useTranslation();

  const handleDelete = useCallback(() => {
    deleteJoinCondition(joinCondition.id);
  }, [deleteJoinCondition, joinCondition]);

  const onSubmit = useCallback(
    async (values: PrimaryMappingFormValues) => {
      return await updateJoinCondition({
        id: joinCondition.id,
        value: `${values.mappingKey[1]},${values.sqlColumnName}`,
        type: "joinCondition",
        integrationId: integration.id,
      });
    },
    [integration.id, joinCondition.id, updateJoinCondition],
  );

  const getInitValues = useCallback(() => {
    const [mappingKey, sqlColumnName] = joinCondition?.value?.split(",") || [];
    // Convert to numbers if they are not undefined
    const parsedMappingKey =
      mappingKey !== undefined ? parseInt(mappingKey, undefined) : undefined;

    const colType = reportColumnTypes?.find(
      (type) => type.id === parsedMappingKey,
    );
    let mappingValue;
    if (colType) {
      mappingValue = [colType.finalTable, colType.id];
    } else {
      mappingValue = undefined;
    }
    const parsedSqlColumnName =
      sqlColumnName !== undefined
        ? parseInt(sqlColumnName, undefined)
        : undefined;

    return {
      mappingKey: mappingValue,
      sqlColumnName: parsedSqlColumnName,
    };
  }, [joinCondition?.value, reportColumnTypes]);

  const updatePrimaryMappingForm: (
    props: FormikProps<PrimaryMappingFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting, isValid }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={9}>
            <Form.Item
              name="mappingKey"
              label="Mapping Key"
              hasFeedback={false}
            >
              <Cascader
                name="mappingKey"
                size="large"
                className="larger-cascade"
                options={cascadeReportColumnTypes(
                  reportColumnTypes,
                  integration.baseTable,
                )}
                placeholder={t("translation:select_column")}
                showSearch
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name="sqlColumnName"
              label="SQL Column Name"
              hasFeedback={false}
            >
              <Select size="large" name="sqlColumnName">
                {mappings?.map((mapping) => (
                  <Select.Option key={mapping.id} value={mapping.id}>
                    {mapping.key}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Row justify="end">
              <SubmitButton
                disabled={!dirty || isSubmitting || !isValid}
                loading={isSubmitting}
                type="primary"
                className="w-full mt-[28px]"
                size="large"
              >
                {t("translation:save")}
              </SubmitButton>
            </Row>
          </Col>
          <Col span={2}>
            <NextButton
              onClick={handleDelete}
              size="md"
              variant="bordered"
              color="default"
              isIconOnly
              className="hover:border-red-500  bg-white mt-[28px]"
            >
              <IconBuilder size={18} color="#e00000" icon="Delete" />
            </NextButton>
          </Col>
        </Row>
      </Form>
    ),
    [handleDelete, integration.baseTable, mappings, reportColumnTypes, t],
  );

  return (
    <Formik
      component={updatePrimaryMappingForm}
      initialValues={getInitValues()}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={simpleSchemaBuilder([
        { name: "mappingKey", type: "array", required: true },
        { name: "sqlColumnName", type: "number", required: true },
      ])}
    />
  );
}
